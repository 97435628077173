import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JobService } from 'src/app/services/job.service';
import { ApplyJobComponent } from '../apply-job/apply-job.component';

@Component({
  selector: 'app-job-details-modal',
  templateUrl: './job-details-modal.component.html',
  styleUrl: './job-details-modal.component.css'
})
export class JobDetailsModalComponent implements OnInit {
  currentJob: any;
  isSaved: boolean = false;
  applyJobDialogRef!: any;
  loading: boolean = true; // Loading state

  constructor(
    private jobService: JobService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dialogRef: MatDialogRef<JobDetailsModalComponent>
  ) {
    
  }

  ngOnInit(): void {
    this.getJobDetails(this.data.id);
  }

  getJobDetails(jobId: string) {
    this.jobService.getJobById(jobId).subscribe((result: any) => {
      this.currentJob = result.data;
      this.loading = false;
    });
  }

  applyNowModalOpen() {
    this.applyJobDialogRef = this.matDialog.open(ApplyJobComponent,
      {
        data: { id: this.data.id }
      }
    );
  }

  saveClick() {
    this.isSaved = !this.isSaved;
  }

  print() {
    window.print();
  }
}
