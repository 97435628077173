import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/appconstants';
import { JobService } from 'src/app/services/job.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrl: './apply-job.component.css'
})
export class ApplyJobComponent {
  currentJob: any;
  isSaved: boolean = false;
  userId: any;
  resumes: any[] = [];
  selectedResume: any;
  loading: boolean = true; // Loading state

  constructor(@Inject(MAT_DIALOG_DATA) public data: { id: string },
    private dialogRef: MatDialogRef<ApplyJobComponent>,
    private jobSearchService: JobService,
    private profileService: ProfileService,
    private jobService: JobService,
    private toastr: ToastrService) {
  }
  ngOnInit(): void {
    this.getJobDetails(this.data.id);
    this.userId = this.profileService.getUserClaims()?.userId;
    this.getAllResumesUploaded();
  }

  getJobDetails(jobId: string) {
    this.jobService.getJobById(jobId).subscribe((result: any) => {
      this.currentJob = result.data;
      this.loading = false;
    });
  }

  getAllResumesUploaded() {
    if (this.userId) {
      this.jobSearchService.getAllResumes(this.userId).subscribe({
        next: (data) => {
          this.resumes = data?.items;
        },
        error: (error) => {
          console.error('Error fetching resumes:', error);
        },
      });
    } else {
      console.warn('User ID is required');
    }
  }


  applyJob(event: MouseEvent) {
    let dataToSend = {
      jobId: this.currentJob?.id,
      resumeId: this.selectedResume?.id,
      userId: this.userId,
      jobApplicationStausId: '46D411CD-99BA-4C2F-81DA-11A75A05A2E2'
    }
    this.jobSearchService.applyJob(dataToSend).subscribe(response => {
      if (response?.success) {
        this.toastr.success(
          AppConstants.JOB_APPLY_SUCCESS_MSG, '',
          {
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-top-right',
            enableHtml: true
          }
        );
          if(this.currentJob?.jobUrl!=null){
            event.preventDefault(); 
            this.dialogRef.close();
            window.open(this.currentJob?.jobUrl, '_blank');
          }
          else
          this.dialogRef.close();
      }
    })
  }
  saveClick() {

  }

  onResumeChange(id: string) {
    this.selectedResume = id;
  }
}
