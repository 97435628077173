<div *ngIf="loading" class="spinner-overlay">
    <div class="spinner"></div>
</div>
<div *ngIf="!loading" class="dialog applyJobModal">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header pb-1 d-flex justify-content-between px-4 pt-3">
                <h2 class="mb-0">Apply for Job</h2>
                <mat-icon class="close-icon" mat-dialog-close>X</mat-icon>
            </div>
            <hr class="custom-hr">
            <mat-dialog-content>
                <div class="modal-body">
                    <div class="right-job-desc-box p-3 m-0">
                        <div class="right-job-desc-top border-0 mb-0 pb-0">
                            <div class="right-jobdesc-company-details">
                                <span class="job-tag-count"><strong>#</strong> {{currentJob?.jobId}}</span>
                                <h3 class="mb-1">{{currentJob?.jobTitle}}</h3>
                                <ul class="d-flex mb-2 flex-wrap">
                                    <li class="d-flex align-items-center">
                                        <img [src]="currentJob?.employer?.companyLogo" class="company-logo">
                                        <span>{{currentJob?.employer?.name}}</span>
                                    </li>

                                    <li class="d-flex align-items-center">
                                        <img src="assets/icons/icon-calendar.svg" alt="">
                                        <span>{{ currentJob?.postDate | date:'dd-MM-yyyy' }}</span>
                                    </li>
                                    <li class="d-flex align-items-center">
                                        <img src="assets/icons/icon-time-progress.svg" alt="">
                                        <span>{{ currentJob?.expiryDate | date:'dd-MM-yyyy' }}</span>
                                    </li>
                                </ul>
                                <div class="job-tags-wrp d-flex justify-content-between flex-wrap">
                                    <div class="job-tags-left d-flex flex-wrap">
                                        <span class="job-chip chip1 mb-2">{{ currentJob?.employmentType?.name}}</span>
                                        <span *ngFor="let industry of currentJob?.industries | keyvalue"
                                            class="job-chip chip2 color-category1 mb-2">
                                            {{ industry?.value }}
                                        </span>
                                        <span class="job-chip  chip3 mb-2"><img
                                                src="../../../assets/icons/icon-money.svg" alt="">{{
                                            currentJob?.compensationMin }}/year - ${{ currentJob?.compensationMax
                                            }}/year</span>
                                    </div>
                                    <div class="job-tags-right d-flex">
                                        <div class="reference-details">
                                            <span>ref: ({{currentJob?.jobId}})</span>
                                        </div>
                                        <div class="job-posted-date">
                                            <span><img src="../../../assets/icons/fi_clock.svg" alt=""> {{
                                                currentJob?.postDate | timeAgo}}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="icons-right-top d-flex">
                                <figure class="tag-img-box mb-0">
                                    <img src="assets/icons/icon-tag.svg" class="m-0" (click)="saveClick()"
                                        *ngIf="!isSaved">
                                    <img src="assets/icons/icon-tag-saved.svg" class="m-0" (click)="saveClick()"
                                        *ngIf="isSaved">
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="select-resume-wrp mt-4">
                        <div class="select-resume-head-top d-flex justify-content-between align-items-center flex-wrap">
                            <h6 class="mb-md-0">Select your Resume</h6>
                            <br>
                            <div class="resume-list-radio-wrp d-flex justify-content-between">
                                <label *ngFor="let resume of resumes" class="resume-radio-btn" [for]="resume.id">
                                    <input type="radio" name="plan" [id]="resume.id" [value]="resume"
                                        [(ngModel)]="selectedResume" (change)="onResumeChange(resume)">
                                    <div class="resume-list-left d-flex align-items-center">
                                        <figure>
                                            <img src="assets/icons/icon-file.svg" alt="">
                                        </figure>
                                        <div class="resume-details">
                                            <h6>{{ resume?.resumeName }}</h6>
                                            <div class="d-flex align-items-center">
                                                <span class="file-size">{{ resume?.size }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="upload-resume-desc d-flex my-3">
                            <p class="mb-0">Upload new resume file or use existing resume profile.<br> New resume file
                                will be saved in My Resumes and made searchable for
                                employers.
                            </p>
                            <div class="btn-resume">
                                <button class="btn btn-outline-primary"><img src="assets/icons/icon-cloud-upload.svg"
                                        alt="">Upload New Resume</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions class="button-group d-flex justify-content-end align-items-center" align="end">
                <button mat-button mat-dialog-close class="btn btn-outline-grey m-2">Cancel</button>
                <button mat-raised-button color="warn" class="btn btn-orange" (click)="applyJob($event)">Apply</button>
            </mat-dialog-actions>
        </div>
    </div>
</div>