<div *ngIf="loading" class="spinner-overlay">
    <div class="spinner"></div>
</div>
<div *ngIf="!loading" class="dialog morejobModal right-job-desc-box">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header pb-1 d-flex justify-content-between px-4 pt-3">
                <div class="right-jobdesc-company-name d-flex align-items-center mb-3">
                    <span><img [src]="currentJob?.employer?.companyLogo" class="company-logo"></span>
                    <h2 class="mb-0" id="exampleModalLabel">{{currentJob?.employer?.name}}</h2>                    
                </div>
                <div class="icons-right-top header-icons-right d-flex">
                    <img src="assets/icons/icon-tag.svg" (click)="saveClick()" *ngIf="!isSaved">
                    <img src="assets/icons/icon-tag-saved.svg" (click)="saveClick()" *ngIf="isSaved">
                    <button mat-icon-button [matMenuTriggerFor]="moreMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #moreMenu="matMenu">
                        <button mat-menu-item>Share Job</button>
                        <button mat-menu-item (click)="print()">Print</button>
                    </mat-menu>                    
                </div>
                <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>   
            </div>
            <mat-dialog-content>
                <div class="modal-body">
                    <div class=" p-3 m-0">
                        <div class="right-job-desc-top border-0 mb-0 pb-0">
                            <div class="right-jobdesc-company-details">
                                <span class="job-tag-count"><strong>#</strong> {{currentJob?.jobId}}</span>
                                <h3 class="mb-1">{{currentJob?.jobTitle}}</h3>
                                <ul class="d-flex mb-2 flex-wrap">
                                    <li class="d-flex align-items-center">
                                        <img _ngcontent-ng-c966856989="" src="assets/icons/icon-building.svg" alt="">
                                        <span>{{currentJob?.employer?.name}}</span>
                                    </li>
    
                                    <li class="d-flex align-items-center">
                                        <img src="assets/icons/icon-calendar.svg" alt="">
                                        <span>{{ currentJob?.postDate | date:'dd-MM-yyyy' }}</span>
                                    </li>
                                    <li class="d-flex align-items-center">
                                        <img src="assets/icons/icon-time-progress.svg" alt="">
                                        <span>{{ currentJob?.expiryDate | date:'dd-MM-yyyy' }}</span>
                                    </li>
                                </ul>
                                <div class="job-tags-wrp d-flex justify-content-between flex-wrap">
                                    <div class="job-tags-left d-flex flex-wrap">
                                        <span class="job-chip chip1 mb-2">{{ currentJob?.employmentType?.name}}</span>
                                        <span *ngFor="let industry of currentJob?.industries | keyvalue"
                                            class="job-chip chip2 color-category1 mb-2">
                                            {{ industry?.value }}
                                        </span>
                                        <span class="job-chip  chip3 mb-2"><img src="../../../assets/icons/icon-money.svg"
                                                alt="">{{ currentJob?.compensationMin }}/year - ${{
                                            currentJob?.compensationMax
                                            }}/year</span>
                                    </div>
                                </div>
    
                            </div>
                            <div class="icons-right-top d-flex">
                                <figure class="tag-img-box mb-0">
                                    <img src="assets/icons/icon-tag.svg" class="m-0" (click)="saveClick()" *ngIf="!isSaved">
                                    <img src="assets/icons/icon-tag-saved.svg" class="m-0" (click)="saveClick()"
                                        *ngIf="isSaved">
                                </figure>
                            </div>
                        </div>
                        <br>
                        <button class="btn btn-apply-now" (click)="applyNowModalOpen()">Apply
                            Now<img src="assets/icons/arrow-right.svg" alt=""></button>
                    </div>
                    <hr>
                    <br>
                    <div class="desc-content1 mb-4">
                        <b>
                            <h4>Description</h4>
                        </b>
                        <p>{{currentJob?.jobDescription}}</p>
                    </div>
                </div>
            </mat-dialog-content>            
        </div>
    </div>
</div>